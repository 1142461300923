@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl
}

h4 {
  @apply text-xl
}

h5 {
  @apply text-lg
}

small {
  @apply text-sm
}

.text-dark {
  @apply text-black dark:text-white
}

button:focus {
  @apply ring-black
}

input:focus {
  @apply ring-0 ring-offset-0
}

button:focus {
  @apply ring-0 ring-offset-0;
}